export default class BreadcrumbsComponent {
    constructor() {
        BreadcrumbsComponent.show()
    }

    static show() {
        const breadcrumbs = '#breadcrumbs'

        if (breadcrumbs.length > 0 && window.matchMedia('(max-width: 1000px)').matches) {
            // On click
            $(breadcrumbs).on('click', () => {
                if (window.matchMedia('(max-width: 1000px)').matches) {
                    $(breadcrumbs).toggleClass('breadcrumbs--show')
                }
            })

            // On focus
            $(breadcrumbs).on('focusin', () => {
                if (window.matchMedia('(max-width: 1000px)').matches) {
                    $(breadcrumbs).addClass('breadcrumbs--show')
                }
            })
        }
    }
}
