export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static SEARCH_TRIGGER = '[data-trigger="wp-search"]'
    static SEARCH_MODAL = '[data-element="wp-search"]'
    static SEARCH_MODAL_INPUT = '[data-element="wp-search"] input'
    static MENU_ITEM = '[data-menu-id]'
    static MENU_WRAPPER = '.submenu__wrapper'
    static MENU_TRIGGER = '[data-menu-trigger]'
}
