/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import FooterService from './services/footer.service'
import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()
    new FooterService()
})
