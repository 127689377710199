import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.search()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
            logo.siblings('source').attr('srcset', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
            logo.siblings('source').attr('srcset', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.MENU_TRIGGER).on('click', function (e) {
            e.preventDefault()
            const menuId = $(this).data('menu-id')
            const menuIdFirst = $(NavbarEnum.MENU_ITEM).first().data('menu-id')

            if (menuId) {
                // Open Submenu selected element
                $(NavbarEnum.BODY).addClass('menu-open')
                $(NavbarEnum.MENU_ITEM).removeClass('is-active')
                $(`[data-menu-id="${menuId}"]`).addClass('is-active')
            } else {
                if (!$(NavbarEnum.BODY).hasClass('menu-open')) {
                    $(NavbarEnum.BODY).addClass('menu-open')
                    // Open Submenu first element on desktop
                    if (window.matchMedia('(min-width: 1025px)').matches) {
                        $(`[data-menu-id="${menuIdFirst}"]`).addClass('is-active')
                    }
                } else {
                    // Close all
                    $(NavbarEnum.BODY).removeClass('menu-open')
                    $(NavbarEnum.MENU_ITEM).removeClass('is-active')
                    setTimeout(() => {
                        $(NavbarEnum.MENU_WRAPPER).scrollTop(0)
                    }, 500)
                }
            }

            //Change logo on mobile
            if (window.matchMedia('(max-width: 1024px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            } else {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }

            //Hide ecomode
            // $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden');
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.MENU_ITEM).removeClass('is-active')
            $(NavbarEnum.MENU_WRAPPER).scrollTop(0)
        })
    }

    static search() {
        if ($(NavbarEnum.SEARCH_MODAL).length > 0) {
            $(NavbarEnum.SEARCH_TRIGGER).on('click', () => {
                $(NavbarEnum.SEARCH_MODAL).toggleClass('is-active')
                $(NavbarEnum.BODY).toggleClass('search-open')

                if ($(NavbarEnum.SEARCH_MODAL).hasClass('is-active')) {
                    $(NavbarEnum.SEARCH_MODAL_INPUT).focus()
                }
            })
        }
    }
}
